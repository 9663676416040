import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import { RightArrowIcon } from '@/components/Icon/ArrowIconUiKit';
import IconWrapper from '@/components/IconWrapper/IconWrapper';
import { LayoutBody, LayoutFooter, LayoutHeader, LayoutWrapper } from '@/components/Layout';
import SpacerFooter from '@/components/Layout/SpacerFooter';
import Markdown from '@/components/Markdown/Markdown';
import Section from '@/components/Section';
import Text from '@/components/Text';
import Seo from '@/widgets/SEO/Seo';

const LogoWrapper = styled(Box)`
  justify-content: left;
  width: fit-content;
  @media (max-width: 1024px) {
    justify-content: left;
    width: fit-content;
    margin-right: 15px;
  }
`;
LogoWrapper.defaultProps = {
  alignItems: 'center',
  display: 'flex',
  pt: '5px',
  pb: '3px',
};

export default function ErrorPage404() {
  const { t } = useTranslation('page-nicht-gefunden');

  return (
    <LayoutWrapper>
      <Seo page="page-nicht-gefunden" />
      <LayoutHeader />
      <LayoutBody>
        <Section>
          <Flex
            flexDirection={['column', 'row', 'row-reverse']}
            alignItems={['center', 'flex-start', 'flex-start']}
            flexWrap="wrap"
            px={['2rem', '8rem', '2rem']}
            mt={['0', '0', '26px']}
          >
            <Flex
              justifyContent={['center', 'flex-end', 'flex-start']}
              width={['50%', '100%', '50%']}
              pl={['0', '0', '6%']}
              mt={['48px', '64px', '64px']}
            >
              <Box width={['100%', '50%', '100%']}>
                <img src="/assets/error/undraw-searching.svg" width="100%" height="100%" alt="icon" />
              </Box>
            </Flex>
            <Box
              textAlign={['center', 'left', 'left']}
              width={['100%', '90%', '50%']}
              pr={['0', '0', '3%']}
              mt={['0', '-6rem', '0']}
            >
              <Box width={['100%', '79%', '75%']} px={['2rem', '0', '0']}>
                <Text variant="title">{t('title')}</Text>
              </Box>
              <Markdown variant="body">{t('infoText')}</Markdown>
              <Box width={['100%', '75%', '70%']}>
                <Link href="/">
                  <a title={t('buttonStart')}>
                    <Button my="7" variant="primary" width="100%" mb={['0', '0', '0']}>
                      <IconWrapper iconColor="white" Icon={RightArrowIcon}>
                        {t('buttonStart')}
                      </IconWrapper>
                    </Button>
                  </a>
                </Link>
                <Link href="/hilfe">
                  <a title={t('buttonContact')}>
                    <Button my="7" variant="secondary" width="100%" mt={['20px', '20px', '20px']}>
                      {t('buttonContact')}
                    </Button>
                  </a>
                </Link>
              </Box>
            </Box>
          </Flex>
        </Section>
        <SpacerFooter />
      </LayoutBody>
      <LayoutFooter />
    </LayoutWrapper>
  );
}
export async function getServerSideProps() {
  return {
    props: {
      hideLayout: true,
    },
  };
}
