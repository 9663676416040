import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { layout } from 'styled-system';

const SpacerContainer = styled(Box)`
  ${layout};
`;
SpacerContainer.defaultProps = {
  minHeight: ['120px', '120px', '120px'],
};

const SpacerFooter = ({ ...props }) => {
  return <SpacerContainer {...props} />;
};

export default SpacerFooter;
